<template>
  <div id="app">
    <div id="main">
      <!-- Header -->
      <header id="header">
        <h1>{{ name }}</h1>
        <div class="description" v-if="langcode == 'en' ">
        {{ descEn }}
        </div>
        <div class="description" v-if="langcode == 'zh' ">
        {{ descZh }}
        </div>
        <ul class="icons">
          <li>{{ $t('Address') }}: {{ $t('FLAT 2, G/F, Prosperity Center, 25 Chong Yip Street, Kwun Tong, Kowloon, Hong Kong') }}</li>
          <li>{{ $t('Email') }}: ycleemotorcycle@outlook.com</li>
        </ul>
      </header>

      <!-- Footer -->
        <footer id="footer">
          <a @click="selectLang('zh')" v-if="langcode === 'en'" href="#">中文</a>
          <a @click="selectLang('en')" v-if="langcode === 'zh'" href="#">EN</a>
          <ul class="copyright">
            <li>&copy; Y. C. LEE MOTORCYCLE SERVICES LIMITED</li>
          </ul>
        </footer>
        <VueBotUI
        :messages="data"
        @msg-send="messageSendHandler"
        />
    </div>
    <div id="viewer">
      <div class="inner">
        <div class="nav-next"></div>
        <div class="nav-previous"></div>
        <div class="toggle"></div>
      </div>
      <div class="slide active">
        <div class="caption"></div>
        <div class="image" style="background-position: left center; background-image: url(&quot;images/fulls/01.jpg&quot;);">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import { VueBotUI } from 'vue-bot-ui';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'App',
  mounted(){
    const langcode = Cookies.get('langcode');
    if(langcode){
      this.$i18n.locale = langcode;
      this.changeLang(langcode);
      document.title = this.$t('Page Title');
    }
    this.loadWebsiteContent();
  },
  components: {
    VueBotUI,
  },
  data() {
    return {
      data: [
        {
          agent: 'bot',
          type: 'button',
          text: 'Hello, Welcome to Y. C. LEE MOTORCYCLE SERVICES LIMITED. How can I help you? ',
          disableInput: true,
          options: [
            {
              text: '你們的營業時間是？',
              value: 'business_hour',
              action: 'postback' // Request to API
            },
            {
              text: '你們的服務內容是？',
              value: 'place_order',
              action: 'postback' // Request to API
            },
            {
              text: '服務資訊',
              value: 'place_order_2',
              action: 'postback' // Request to API
            },
          ],
        }
      ],
      replyTexts: [
        {
          'question_text': "你們的營業時間是？",
          'question': 'business_hour',
          'answer': '以下是我們的服務時間：週一 上午9:00 - 下午7:00 週二 上午9:00 - 下午7:00 週三 上午9:00 - 下午7:00 週四 上午9:00 - 下午7:00 週五 上午10:00 - 下午7:00 週六 上午10:00 - 下午7:00 週日 未營業',
        },
        {
          'question_text': "你們的服務內容是？",
          'question': 'place_order',
          'answer': '歡迎預約安裝,任何查詢請WHATSAPP至852-63033880 我們支持路邊取貨/到店取貨',
        },
        {
          'question_text': "服務資訊",
          'question': 'place_order_2',
          'answer': '任何新資訊瀏覽歡迎：https://reurl.cc/NQjqXQ',
        },
      ],
      name: '',
      descEn: '',
      descZh: '',
      contact: '',
    }
  },
  methods:{
    async loadConfig(){
      return new Promise(async(resolve, reject) => {
        const baseApiUrl = process.env.VUE_APP_API_URL;
        const apiUrl = `${baseApiUrl}/website/content`;
        const fetchResult = await fetch(apiUrl, {
          method: 'GET',
          headers: {
           'Content-type': 'application/json; charset=UTF-8'
          },
        });

        const status = await fetchResult.status;

        //If cannot connect with API server
        if(status !== 200 && status !== 401){
          const err = 'Cannot load system config. Maybe there are some network problem?';
          reject(err);
          return;
        }

        //If user do not login or no permission
        if(status === 401){
          const err = 'Sorry, You don\'t had permission to access it.';
          reject(err);
          return;
        }

        const resultJson = await fetchResult.json();

        resolve(resultJson);
      });
    },
    async loadWebsiteContent(){
      try{
        let websiteContent = await this.loadConfig();

        this.name = websiteContent['website_name'];
        this.descZh = websiteContent['website_introduce_zh'];
        this.descEn = websiteContent['website_introduce_en'];
        this.contact = websiteContent['company_contact'];

      }catch(err){
        console.log(err);
      }
    },
    selectLang(langcode){
      Cookies.set('langcode', langcode);
      this.$i18n.locale = langcode;
      this.changeLang(langcode);
      document.title = this.$t('Page Title');
    },
    messageSendHandler(userAction){
      // Add user question
      const userQuestion = {
        agent: 'user',
        type: 'text',
        text: userAction['text'],
        disableInput: false,
      }
      this.data.push(userQuestion);

      //Start reply answer
      let question = userAction['value'];
      let answer = this.replyTexts.find(function(item){
        return item.question === question
      });
      //If user is trying to input text
      if(!userAction['value']){
        question = userAction['text'];
        answer = this.replyTexts.find(function(item){
        return item.question_text === question
      });
      //Get chinese version answer
        if(this.$i18n.locale === 'zh'){
          answer = this.replyTexts.find(function(item){
            return item.question_text_zh === question
          });
        }
      }
      //If answer is able to reply
      let reply = {
        agent: 'bot',
        type: 'text',
        text: 'Sorry, I cannot understand this question.',
        disableInput: false,
      }
      if(this.$i18n.locale === 'zh'){
        reply.text = '對不起，我無法理解這個問題。';
      }

      if(answer){
        answer = answer['answer'];
        reply = {
          agent: 'bot',
          type: 'text',
          text: answer,
          disableInput: false,
        }
      }
      this.data.push(reply);
    },
    ...mapActions(['changeLang']),
  },
  computed: mapState({
   langcode: state => state.langcode,
 }),
}
</script>

<style lang="scss">
h1{
  text-align: left;
}
body.is-preload-1 #main{
  opacity: 1;
  right: 0;
}
.qkb-board{
  width: 30vw;
}
.qkb-board-action{
  height: auto;
}
.description{
  text-align: left;
  margin-bottom: 20px;
}
</style>
